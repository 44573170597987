@import "../main/abstracts/index";
.section--article-list{
    padding: 80px 0;
    header h2{
        text-align: center;
        position: relative;
        @media(max-width: 600px){
            text-align: right;
        }
        &:before{
            content: "";
            width: 90px;
            height: 50px;
            background-image: url('/assets/website/img/eber.svg');
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            left: 0;
            bottom: 13px;
            @media(max-width: 600px){
                width: 38px;
                height: 21px;
                bottom: 6px;
            }
        }
        &:after{
            content: "";
            width: 50%;
            left: 110px;
            position: absolute;
            bottom: 13px;
            height: 1px;
            background-color: $color__black;
            @media(max-width: 600px){
                bottom: 6px;
                left: 41px;
            }
        }
        span{
            position: relative;
            z-index: 1;
            background-color: $color__gray--light;
            padding-left: 20px;
            @media(max-width: 600px){
                padding-left: 0;
            }
        }
    }
    .splide:not(.splide--custom) .article-list__footer{
        .splide__navigation{
            &:after{
                content: "";
                flex: 1;
                @media(max-width: 900px){
                    display: none;
                }
            }
        }
        .splide:not(.splide--custom) .splide__link{
            flex: unset;
        }
        .splide__pagination{
            gap: 0 10px;
            margin-left: 10px;
            &__page{
                width: 20px;
                height: 20px;
                &:before{
                    width: 10px;
                    height: 10px;
                    border-radius: 5px;
                    background-color: $color__black;
                    @include transition
                }
                &:after{
                      content: "";
                      width: 8px;
                      height: 8px;
                      border-radius: 15px;
                      border: 0px solid $color__primary;
                      background-color: transparent;
                      @include transition;
                      position: absolute;
                      left: 50%;
                      top: 50%;
                      transform: translate(-50%,-50%);
    
                }
                @include hover-focus-visible{
                    &:before{
                        background-color: $color__primary;
                    }
                }
                &.is-active{
                    &:before{
                        background-color: $color__primary;
                    }
                    &::after{
                        width: 27px;
                        height: 27px;
                        border-width: 2px;
                    }
    
                }
            }
    
        }
        .splide__arrows{
            gap: 0 5px;
            flex: unset;
            margin-right: 25px;
        }
        .splide__arrow{
            margin: 0;
            padding: 10px 5px;
            background-color: $color__gray--light;
            @include transition;
            &--next,
            &--prev{
                span img{
                    height: 30px;
                    width: 30px;
                    filter: $filter__black;
                    @include transition;
                }
                @include hover-focus-visible{
                    background-color: $color__primary;
                    span img{
                        filter: $filter__white
                    }
                }
            }
            &--prev{
                span img{
                    transform: rotate(180deg);
                }
            }
        }
    }

}
.newsHome{
    &__item{
        position: relative;
        &:after{
            content: "";
            height: 100%;
            width: 1px;
            background-color: #CBCBCB;
            position: absolute;
            right: -12px;
            top: 0;
        }
        a{
            text-decoration: none;
            display: block;
            height: 100%;
            display: flex;
            flex-direction: column;
            @include hover-focus-visible{
                h3{
                    text-decoration: underline;

                }
                .newsHome__upper span{
                    background-color: $color__primary;
                    img{
                       filter: $filter__white;
                    }
                }
                &:not(.newsHome__itemImage) &{
                    
                }
                .newsHome__down{
                    img{
                        transform: scale(1.1);
                    }
                }
            }
        }
        &:not(.newsHome__itemImage) {
            @include hover-focus-visible{
                .newsHome__upper span{
                    background-color: $color__white;
                    img{
                        filter: $filter__primary
                    }
                
                }  
                .newsHome__down{
                    img{
                        transform-origin: right;
                    }
                }
            }
           
        }
       
    }
    &__itemImage{
        .newsHome__upper{
            // background-color: $color__white;
        }
    }
    &__upper{
        padding: 14px 24px 0 24px;
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        color: $color__black;
        .newsHome__item:not(.newsHome__itemImage) &{
            background-color: $color__primary;
            color: $color__white;
            &:before{
                content: "";
                position: absolute;
                left: 0;
                width: calc(100% - 96px);
                height: 1px;
                background-color: $color__white;
                bottom: 0;
            }
        }
        h3{
            margin-top: 24px;
            margin-bottom: 95px;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4; /* number of lines to show */
                    line-clamp: 4;
            -webkit-box-orient: vertical;
        }
        span{
            position: absolute;
            right: 24px;
            bottom: 0;
            border-radius: 4px;
            transform: translateY(50%);
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            background-color: $color__black;
            align-items: center;
            z-index: 1;
            img{
                width: 20px;
                filter: $filter__white;
            }
        }
    }
    &__down{
        padding-top: 75%;
        position: relative;
        overflow: hidden;
        img{
            position: absolute;
            left: 0%;
            top: 0;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            object-position: center;
            @include transition;
        }
        .newsHome__item:not(.newsHome__itemImage) &{
            background-color: $color__primary; 
            padding-top: 75%;
            position: relative;
            img{
                position: absolute;
                right: 0px;
                left: unset;
                top: 0;
                width: 70%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: contain;
                object-position: bottom right;
                filter: $filter__white;
            }
        }
       
    }
    
}